import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getSnackBarConfig } from '../utils/material.utils';
import { Subscription, fromEvent } from 'rxjs';
import { GooglePlace } from './model/googleplace.interface';
import { GoogleMapsService } from '../services/google-maps.service';
import { canMakeCityPost, getCityPostsData } from '../utils/profile.utils';
import { StorageService } from '../services/storage.service';

@Directive({
  selector: '[hbGooglePlace]',
  providers: [NgModel],
  standalone: true
})
export class GoogleplaceDirective implements AfterViewInit, OnDestroy {
  @Input() public locationType: string;
  @Output() public setAddress: EventEmitter<GooglePlace> = new EventEmitter<GooglePlace>();
  private countryAutocomplete: google.maps.places.Autocomplete;
  private blurSubscription: Subscription;
  private cityAutocomplete: google.maps.places.Autocomplete;

  constructor(private snackBar: MatSnackBar, private renderer: Renderer2, private googleMapsService: GoogleMapsService, private storageService: StorageService) {}

  public ngAfterViewInit(): void {
    this.googleMapsService.isPlacesLoaded$.subscribe(() => {
      this.initAutocompleteForCityInput();
      this.startLocationListener();
    });
  }

  public ngOnDestroy(): void {
    if (this.blurSubscription) {
      this.blurSubscription.unsubscribe();
    }
  }

  private startLocationListener(): void {
    if (this.locationType) {
      const countryInput = this.renderer.selectRootElement('[name="country"]') as HTMLInputElement;
      this.countryAutocomplete = new google.maps.places.Autocomplete(countryInput, {
        types: ['country']
      });

      google.maps.event.addListener(this.countryAutocomplete, 'place_changed', () => {
        const place = this.countryAutocomplete.getPlace();

        if (place?.address_components) {
          this.storageService.setValue('store-countryName', place.address_components[0].long_name);
          this.storageService.setValue('store-country', place.address_components[0].short_name);

          if (this.cityAutocomplete) {
            google.maps.event.clearInstanceListeners(this.cityAutocomplete);
          }
          this.initAutocompleteForCityInput();
        }
      });
    }
  }

  private initAutocompleteForCityInput(): void {
    //ToDo: realize range
    // const regionBounds = new google.maps.LatLngBounds(
    //   new google.maps.LatLng(49.18, 23.12),
    //   new google.maps.LatLng(50.03, 24.18)
    // )
    const cityInput = this.renderer.selectRootElement('[name="city"]') as HTMLInputElement;
    const postsData = getCityPostsData(this.storageService);
    
    if (cityInput) {
      this.cityAutocomplete = new google.maps.places.Autocomplete(cityInput, {
        componentRestrictions: { country: this.storageService.getValue('store-country') || 'UA' },
        types: ['(cities)']
        // bounds: regionBounds,
        // strictBounds: true,
      });

      google.maps.event.addListener(this.cityAutocomplete, 'place_changed', () => {
        if (canMakeCityPost(postsData)) {
          const place = this.cityAutocomplete.getPlace();
          if (place?.formatted_address) {
            const placeObj: GooglePlace = {
              formatted_address: place?.formatted_address,
              name: place.name || '',
              place_id: place.place_id
            };
            
            postsData.lastEditTimestamp = new Date().getTime();
            postsData.count++;
            this.storageService.setValue('store-cityInputData', JSON.stringify(postsData));
  
            this.setAddress.emit(placeObj);
          }
        } else {
          this.snackBar.open($localize`The city can be modified in just 1 minute`, $localize`:@@close:Close`, getSnackBarConfig());
        }
        
      });

      this.blurSubscription = fromEvent(cityInput, 'blur').subscribe(() => {
        if (!this.cityAutocomplete.getPlace() && this.storageService.getValue('store-city')) {
          this.snackBar.open($localize`Please select a city`, 'Close', getSnackBarConfig());
        }
      });
    }
  }
}
