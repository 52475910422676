import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { getSnackBarConfig } from '../utils/material.utils';
import { ProfileService } from '../core/profile/profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GooglePlace } from '../shared/model/googleplace.interface';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private cityChangeSubject = new Subject<string>();
  public cityChange$: Observable<string> = this.cityChangeSubject.asObservable();

  constructor(private snackBar: MatSnackBar, private profileService: ProfileService, private storageService: StorageService) {
  }

  public notifyCityChange(city: string | null): void {
    if (city) {
      this.cityChangeSubject.next(city);
    }
  }

  public setCityOption(place: GooglePlace): void {
    const selectedCity = place.formatted_address.replace(/,\s\d+$/, '');
    const objToUpdate = { city: selectedCity };
    this.profileService.updateUser(objToUpdate).subscribe(() => {
      this.storageService.setValue('store-cityName', place.name);
      this.storageService.setValue('store-city', selectedCity);
      this.storageService.setValue('store-placeId', place.place_id || "");
      this.snackBar.open($localize`The city is changed`, 'Close', getSnackBarConfig());
    });
  }
}
