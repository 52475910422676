<div class="top-bar flex-row">
  <mat-icon class="arrow-back cursor-pointer" (click)="navigateToEventsPage()">arrow_back</mat-icon>
  <span class="page-label justify-center flex-1" i18n>Setting</span>
</div>
<div class="settings-form">
  <form [formGroup]="settingsForm" class="flex-col space-between align-center gap-25">
    <mat-form-field appearance="fill" class="custom-mat-form-field">
      <mat-label class="mat-label">Select Country</mat-label>
      <input formControlName="country" matInput hbGooglePlace [locationType]="'country'" name="country" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="custom-mat-form-field">
      <mat-label class="mat-label">Select City</mat-label>
      <input
        formControlName="city"
        matInput
        hbGooglePlace
        [locationType]="'city'"
        (setAddress)="setCityData($event)"
        name="city"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" class="custom-mat-form-field h-40">
      <mat-label class="mat-label" i18n>Language</mat-label>
      <mat-select formControlName="lang" panelClass="select-panel" required (selectionChange)="onLanguageChange()">
        <mat-option [value]="'en'">
          {{ 'English' }}
        </mat-option>
        <mat-option [value]="'uk'">
          {{ 'Ukrainian' }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="custom-mat-form-field h-40">
      <mat-label class="mat-label" i18n aria-label="toggle dark vs light theme">Theme</mat-label>
      <mat-select formControlName="theme" panelClass="select-panel" required (selectionChange)="onThemeChange($event)">
        <mat-option [value]="'light'">
          {{ 'Light' }}
        </mat-option>
        <mat-option [value]="'dark'">
          {{ 'Dark' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div class="contact-info">
    <p i18n>Contact Info</p>
    <!-- address -->
    <div class="flex-row space-between align-center gap-25">
      <div class="contact-div">
        <mat-icon svgIcon="home"></mat-icon>
        <p class="info-text contact-info-text">28 Zhovkivska Str, Lviv, Ukraine, 79019</p>
      </div>
    </div>
    <!-- email -->
    <div class="flex-row space-between align-center gap-25">
      <div class="contact-div mt-5">
        <mat-icon svgIcon="email"></mat-icon>
        <a href="mailto:office@ngmates.com" class="info-text contact-info-text">office&#64;ngmates.com</a>
      </div>
    </div>
    <!-- phone -->
    <div class="flex-row space-between align-center gap-25">
      <div class="contact-div mt-5">
        <mat-icon svgIcon="phone"></mat-icon>
        <p class="info-text contact-info-text">+38(095)281-66-78</p>
      </div>
    </div>
  </div>

  <div>
    <p i18n>Social icons</p>
    <div class="flex-row start align-center gap-25">
      <mat-icon svgIcon="facebook" class="h-16 w-16 social-icon"></mat-icon>
      <mat-icon svgIcon="instagram" class="h-16 w-16 social-icon"></mat-icon>
      <mat-icon svgIcon="twitter" class="h-16 w-16 social-icon"></mat-icon>
      <mat-icon svgIcon="call" class="h-16 w-16 social-icon"></mat-icon>
    </div>
  </div>

  <div class="faq-policy">
    <a href="https://hobbytostar.web.app/privacy_policy" target="_blank" i18n>Privacy Policy</a>
    <a href="https://hobbytostar.web.app/terms_of_services" target="_blank" i18n>Terms of Service</a>
  </div>
</div>
