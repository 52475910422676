import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { StorageService } from './storage.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ColorSchemeService {
  private renderer: Renderer2;
  private colorScheme = 'dark';
  // Define prefix for clearer and more readable class names in scss files
  private colorSchemePrefix = 'color-scheme-';

  constructor(rendererFactory: RendererFactory2, private storageService: StorageService, @Inject(PLATFORM_ID) private platformId: string) {
    // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public load(): void {
    this.getColorScheme();
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.addClass(document.body, this.colorSchemePrefix + this.colorScheme);
    }
  }

  public update(scheme: string): void  {
    this.setColorScheme(scheme);
    if (isPlatformBrowser(this.platformId)) {
      // Remove the old color-scheme class
      this.renderer.removeClass(document.body, this.colorSchemePrefix + (this.colorScheme === 'dark' ? 'light' : 'dark'));
      // Add the new / current color-scheme class
      this.renderer.addClass(document.body, this.colorSchemePrefix + scheme);
    }
  }

  public currentActive(): string {
    return this.colorScheme;
  }

  private setColorScheme(scheme: string): void  {
    this.colorScheme = scheme;
    // Save prefers-color-scheme to localStorage
    this.storageService.setValue('store-prefers-color', scheme);
  }

  private getColorScheme(): void  {
    const localStorageColorScheme = this.storageService.getValue('store-prefers-color');
    // Check if any prefers-color-scheme is stored in localStorage
    if (localStorageColorScheme) {
      // Save prefers-color-scheme from localStorage
      this.colorScheme = localStorageColorScheme;
    }
  }
}
