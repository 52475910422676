import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ColorSchemeService } from 'src/app/services/theme-color-scheme.service';
import { GoogleplaceDirective } from 'src/app/shared/google-places.directive';
import { MatInputModule } from '@angular/material/input';
import { ConfigService } from 'src/app/services/config.service';
import { GooglePlace } from 'src/app/shared/model/googleplace.interface';
import { CookieService } from 'src/app/services/cookie.service';
import { setLangCookies } from 'src/app/utils/profile.utils';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'hb-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    GoogleplaceDirective,
    MatInputModule
  ]
})
export class SettingsComponent implements OnInit {
  @HostBinding('class.background-host-page') public backgroundHostPageClass = true;
  public settingsForm!: FormGroup;

  constructor(
    private configService: ConfigService,
    private router: Router,
    private formBuilder: FormBuilder,
    private colorSchemeService: ColorSchemeService,
    private cookieService: CookieService,
    private storageService: StorageService
  ) {}

  public get city(): FormControl {
    return this.settingsForm.get('city') as FormControl;
  }

  public get lang(): FormControl {
    return this.settingsForm.get('lang') as FormControl;
  }

  public ngOnInit(): void {
    const lang = this.storageService.getValue('store-language') || navigator.language?.split('-')[0] || 'en';
    const currentCountry = this.storageService.getValue('store-countryName');
    const currentTheme = this.storageService.getValue('store-prefers-color');
    const currentCity = this.storageService.getValue('store-city');
    this.settingsForm = this.formBuilder.group({
      country: [currentCountry],
      city: [currentCity, Validators.required],
      lang: [lang, Validators.required],
      theme: [currentTheme]
    });
  }

  public setCityData(place: GooglePlace): void {
    this.configService.setCityOption(place);
  }

  public navigateToEventsPage(): void {
    void this.router.navigate(['home']);
  }

  public async onLanguageChange(): Promise<void> {
    let country = 'GB',
    lang = 'en';

    if (this.lang.value === 'uk') {
      country = 'UA';
      lang = 'uk';
    }

    setLangCookies(this.cookieService, country, lang);
    this.storageService.setValue('store-language', lang);

    await this.router.navigateByUrl('/');
    // page reload should be made after getCityNameByPlaceId on lang change
  }

  public onThemeChange(event: MatSelectChange): void {
    const selectedValue: string = event.value as string;
    this.colorSchemeService.update(selectedValue);
  }
}
