import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { isPlatformBrowser } from '@angular/common';
import { FeatureFlagService } from './feature-flag.service';
import { reloadInPWA } from '../utils/common.utils';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private isPlacesLoaded = new BehaviorSubject(false);
  public isPlacesLoaded$ = this.isPlacesLoaded.pipe(
    filter(Boolean)
  );
  private loader: any;

  constructor(private http: HttpClient, private storageService: StorageService, @Inject(PLATFORM_ID) private platformId: string, private featureFlagService: FeatureFlagService,) {

    if(!this.featureFlagService.getFeature()) {
      this.storageService.valueChanges("store-language").subscribe((language) => {
        this.getCityNameByPlaceId(this.storageService.getValue("store-placeId") || "", language || "en").subscribe((data) => {
          const formatted_address = data.results[0].formatted_address.replace(/,\s\d+$/, '');
          this.storageService.setValue('store-city', formatted_address);
          this.storageService.setValue('store-cityName', formatted_address.replace(/^([^,]+).*$/, '$1').trim());

          if (isPlatformBrowser(this.platformId)) {
            reloadInPWA();
          }
        });

        this.initLoader(language);
      });
    }

    this.initLoader();
  }

  public getCityName(lat: number, lng: number, language: string): Observable<any> {
    return this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=${language}&key=${environment.googleMapsApiKey}`);
  }

  public getCityNameByPlaceId(placeId: string, language: string): Observable<any> {
    return this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&language=${language}&key=${environment.googleMapsApiKey}`);
  }

  public getPlaceIdByCityName(cityName: string): Observable<any> {
    return this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(cityName)}&key=${environment.googleMapsApiKey}`);
  }

  private initLoader(language?: string | null): void {
    this.loader = new Loader({
      apiKey: environment.googleMapsApiKey,
      version: "weekly",
      language: language || this.storageService.getValue("store-language") || "uk"
    });

    this.loader.importLibrary("places").then(async () => {
      this.isPlacesLoaded.next(true);
    });
  }
}
